import { Breadcrumbs, emptyFragment, useMediaQuery } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { findLink } from '../../../../../../LinkyLinky';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import { RegisterResource } from '../../../../DiscoveryPortal/RegisterResourceStore/Types';
import ExperienceCrump from './ExperienceCrump';
import ExploreCrumb from './ExploreCrump';
import { learnMoreBreadcrumbsColor } from './Types';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  registerResource: RegisterResource;
}

function HeroBreadcrumbs({ invitationUuid, experienceResource, registerResource }: Props) {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
      return (
        <Breadcrumbs
          aria-label="Breadcrumb"
          className={clsx('mb-9', learnMoreBreadcrumbsColor(experienceResource.payload))}
        >
          {findLink('discovery-portal', registerResource.payload.sharedInvitation.links)
            .map(() => (
              <ExploreCrumb
                invitationUuid={invitationUuid}
                kind={experienceResource.payload.registrationInvitationKind}
                name={registerResource.payload.sharedInvitation.payload.name}
              />
            ))
            .getOrElse(emptyFragment)}
          <ExperienceCrump title={experienceResource.payload.title} />
        </Breadcrumbs>
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return emptyFragment();
  }
}

export default observer(HeroBreadcrumbs);
